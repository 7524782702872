import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BasicService } from '../common/services/basic.service';
import { API_VOUCHER_PROVIDER_URL } from '../connection.data';

@Injectable()
export class VoucherProviderService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_VOUCHER_PROVIDER_URL;
    }
}